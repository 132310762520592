import React, { FC } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {
  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Join our newsletter 🎉</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Stay updated with the latest repositioning cruise deals and tips. Join our community—everyone's welcome!
        </span>
        <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01"/>
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get bigger discounts
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02"/>
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get access to new cruises first
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="yellow" name="02"/>
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get inspiration from cruise experts
            </span>
          </li>
        </ul>
        <form className="mt-10 relative max-w-sm">
          <Input
              required
              aria-required
              placeholder="Enter your email"
              type="email"
              rounded="rounded-full"
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-[5px]"
          >
            <i className="las la-arrow-right text-xl"></i>
          </ButtonCircle>
        </form>
      </div>
      <div className="flex-grow">
        <img src="https://images.unsplash.com/photo-1504434026032-a7e440a30b68?crop=entropy&cs=tinysrgb&fit=crop&auto=format&ixid=M3wzMDIzOTR8MHwxfHNlYXJjaHwzfHxwbHVtYmVyfGVufDB8fHx8MTcwOTk3OTk2OXww&ixlib=rb-4.0.3&q=80&w=1280&h=720&fit=crop" className="object-cover w-full h-full rounded-3xl"
             alt="nc-imgs"/>
      </div>
    </div>
  );
};

export default SectionSubscribe2;
