import React, { FC } from "react";
import TabFilters from "./TabFilters";
import Heading2 from "components/Heading/Heading2";
import FlightCard, { FlightCardProps } from "components/FlightCard/FlightCard";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface SectionGridFilterCardProps {
  className?: string;
}

const DEMO_DATA: FlightCardProps["data"][] = [
  {
    id: "1",
    price: "$1,100",
    airlines: {
      logo: "https://companieslogo.com/img/orig/RCL-8028a7c6.png?t=1720244493",
      name: "Royal Caribbean",
    },
  },
  {
    id: "2",
    price: "$1,800",
    airlines: {
      logo: "https://seeklogo.com/images/P/Princess_Cruises-logo-4D0E249705-seeklogo.com.png",
      name: "Princess",
    },
  },
  {
    id: "3",
    price: "$1,400",
    airlines: {
      logo: "https://companieslogo.com/img/orig/RCL-8028a7c6.png?t=1720244493",
      name: "Royal Caribbean",
    },
  },
  {
    id: "4",
    price: "$1,200",
    airlines: {
      logo: "https://seeklogo.com/images/P/Princess_Cruises-logo-4D0E249705-seeklogo.com.png",
      name: "Princess",
    },
  },
  {
    id: "5",
    price: "$1,900",
    airlines: {
      logo: "https://companieslogo.com/img/orig/RCL-8028a7c6.png?t=1720244493",
      name: "Royal Caribbean",
    },
  },
];

const SectionGridFilterCard: FC<SectionGridFilterCardProps> = ({
  className = "",
}) => {
  return (
    <div
      className={`nc-SectionGridFilterCard ${className}`}
      data-nc-id="SectionGridFilterCard"
    >
      <Heading2
        heading="Cruise Search Results"
        subHeading={
          <span className="block text-neutral-500 dark:text-neutral-400 mt-3">
            22 cruises
            <span className="mx-2">·</span>
            Caribbean
            <span className="mx-2">·</span>
            4 Travelers
          </span>
        }
      />
      <div className="mb-8 lg:mb-11">
        <TabFilters />
      </div>
      <div className="lg:p-10 lg:bg-neutral-50 lg:dark:bg-black/20 grid grid-cols-1 gap-6  rounded-3xl">
        {DEMO_DATA.map((item, index) => (
          <FlightCard defaultOpen={!index} key={index} data={item} />
        ))}

        <div className="flex mt-12 justify-center items-center">
          <ButtonPrimary loading>Show more</ButtonPrimary>
        </div>
      </div>
    </div>
  );
};

export default SectionGridFilterCard;
